<template>
  <div>
    <div class="storyview">
      <div class="itembox">
        <div class="userbox">
          <el-avatar
            class="avatar"
            :src="story.Avatar"
            shape="square"
            :size="50"
          ></el-avatar>
          <div class="info">
            <b class="name">{{ story.Nickname }}</b>
            <p class="date">{{ formatDate(story.Addtime * 1000) }}</p>
          </div>
        </div>
        <div class="content" v-html="story.Content"></div>
        <div class="share">
          <img
            src="../assets/share/sns_icon_22@2x_b.png"
            alt=""
            @click="openShare('weixin')"
          />
          <img
            src="../assets/share/sns_icon_24@2x_b.png"
            alt=""
            @click="openShare('qq')"
          />
          <!-- <img
            src="../assets/share/sns_icon_6@2x_b.png"
            alt=""
            @click="openShare('qzone')"
          /> -->
          <img
            src="../assets/share/sns_icon_1@2x_b.png"
            alt=""
            @click="openShare('weibo')"
          />
          <img
            src="../assets/share/sns_icon_5@2x_b.png"
            alt=""
            @click="openShare('douban')"
          />
          <!-- <el-button class="testshare" @click="testShare()">测试分享</el-button> -->

          <!--MOB SHARE BEGIN-->
          <!-- <span class="-mob-share-ui-button -mob-share-open">
            <img src="../assets/mh/mh_2.png" alt="分享" />
          </span>
          <div class="-mob-share-ui" style="display: none">
            <ul class="-mob-share-list">
              <li class="-mob-share-weixin"><p>微信</p></li>
              <li class="-mob-share-qq"><p>QQ好友</p></li>
              <li class="-mob-share-qzone"><p>QQ空间</p></li>
              <li class="-mob-share-weibo"><p>新浪微博</p></li>
              <li class="-mob-share-douban"><p>豆瓣</p></li>
              <li class="-mob-share-facebook"><p>Facebook</p></li>
              <li class="-mob-share-twitter"><p>Twitter</p></li>
            </ul>
            <div class="-mob-share-close">取消</div>
          </div>
          <div class="-mob-share-ui-bg"></div> -->
          <!--MOB SHARE END-->

          <!-- <el-button type="warning" icon="el-icon-share" circle></el-button> -->
          <!-- <Sharethis
            share-this-embed-url="//platform-api.sharethis.com/js/sharethis.js#property=600d6ff8e7f5c10011bd341a&product=sop"
          /> -->

          <!-- <div class="button share-button facebook-share-button">share</div>
          <div class="button share-button twitter-share-button">tweet</div>
          <div
            class="button share-button wechat-share-button"
            data-url="/storydetail/1"
          >
            微信
          </div>
          <div class="button share-button weibo-share-button">微博</div>
          <div class="button share-button qzone-share-button">QQ空间</div>
          <div class="button share-button renren-share-button">人人</div>
          <div class="button share-button douban-share-button">豆瓣</div>
          <Sharethis
            share-this-embed-url="//platform-api.sharethis.com/js/sharethis.js#property=600d6ff8e7f5c10011bd341a&product=sticky-share-buttons&cms=sop"
          /> -->

          <!-- <div
            class="social-share"
            data-disabled="google"
            data-description="Share.js - 一键分享到微博，QQ空间，腾讯微博，人人，豆瓣"
          ></div> -->

          <!-- <share :config="config"></share> -->

          <!-- <div
            class="social-share"
            data-sites="weibo,wechat"
            data-url="地址"
            :data-title="分享标题"
          ></div> -->
        </div>
      </div>

      <!-- <div class="commentbox">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入评论"
          v-model="comment"
        >
        </el-input>
        <div class="submit">
          <el-button type="success">发送</el-button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueScript2 from "vue-script2";
// import social-share from 'social-share'
// import Sharethis from "vue-sharethis";
// import Share from "vue-social-share";
import webconfig from "../plugins/config";

export default {
  name: "StoryDetail",
  components: {
    // Share,
    // Sharethis,
  },
  head: {
    title: function () {
      return {
        inner: this.title,
      };
    },
    meta: function () {
      return [
        {
          name: "keywords",
          content: this.keywords,
          id: "keywords",
        },
        {
          name: "description",
          content: this.description,
          id: "description",
        },
      ];
    },
  },
  data() {
    return {
      comment: "",
      showShare: true,
      loadscript: [],
      title: "幕后故事详情 - 沉浸式恐怖互动小说",
      keywords: "幕后故事,当火车鸣笛3秒,火车鸣笛,鸣笛官网,3秒官网",
      description: "当火车鸣笛3秒幕后故事,沉浸式恐怖互动小说,当火车鸣笛3秒官网",
      story: {
        Stroyid: 0,
        Nickname: "",
        Avatar: "",
        Intro: "",
        Content: "",
        Img: "",
      },
    };
  },
  mounted: function () {
    console.log("mouted111");
    console.log(this.$route.params);
    // this.story = this.$route.params;

    // this.showShare = false;
    // VueScript2.load(
    //   "http://f1.webshare.mob.com/code/mob-share.js?appkey=m322ec43c6ede5"
    // ).then(() => {
    //   console.log("Hello from VueScript2!");
    //   this.showShare = true;
    // });

    // setTimeout(() => {
    //   this.showShare = true;
    // }, 3000);

    // 创建script标签，引入外部文件
    // let script = document.createElement("script");
    // script.type = "text/javascript";
    // script.src =
    //   "http://f1.webshare.mob.com/code/mob-share.js?appkey=m322ec43c6ede5";
    // document.getElementsByTagName("head")[0].appendChild(script);

    // this.$nextTick(() => {
    //   VueScript2.load(
    //     "http://f1.webshare.mob.com/code/mob-share.js?appkey=m322ec43c6ede5"
    //   ).then(function () {
    //     console.log("Hello from VueScript2!");
    //   });
    // });

    axios
      .post(webconfig.APIURL + "/story/detail", {
        Storyid: this.$route.params.storyid,
      })
      .then(
        (res) => {
          console.log(res.data);
          if (res.data) {
            this.story = res.data.data;
            if (this.story) {
              this.title = this.story.Intro + " - 幕后故事";
              this.description = this.story.Intro + ",当火车鸣笛3秒幕后故事";
              // console.log(this.title);
              this.$emit("updateHead");
            }
          }
        },
        (error) => {}
      );
  },
  created: function () {
    console.log("created2222");
    let val = require("../assets/js/js_8.png");
    this.$emit("showBg", val);
  },
  methods: {
    //时间戳转换方法    date:时间戳数字
    formatDate(date) {
      var date = new Date(date);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD;
      // return YY + MM + DD + " " + hh + mm + ss;
    },
    openShare(type) {
      console.log("测试分享");
      mobShare.config({
        appkey: "m322ec43c6ede5", // appkey
        params: {
          url: "", // 分享链接
          title: this.story.Intro, // 分享标题
          description: this.story.Intro, // 分享内容
          pic: this.story.Img, // 分享图片，使用逗号,隔开
          reason: "", //自定义评论内容，只应用与QQ,QZone与朋友网
        },
      });

      var mod = mobShare(type);
      mod.send();
    },
  },
};
</script>

<style lang="scss">
.storyview {
  width: 80%;
  max-width: 700px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .share,
  .submit {
    text-align: right;
    padding: 20px 10px;
  }
  .share {
    img {
      width: 30px;
      margin: 0 5px;
      cursor: pointer;
    }
    .-mob-share-ui-button {
      background: none;
      padding: 0;
      display: inline-block;
    }
  }
  .commentbox {
    background-color: #02140c;
    .el-textarea {
      padding: 0 20px;
      display: block;
      width: auto;
      textarea {
        border: solid 1px #2f5b3d;
        background: none;
        background-color: #010a06;
      }
    }
  }
}
.itembox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  background-color: #02140c;
  font-family: "oswald", "SourceHanSansSC-Light", "Source-Han-Light",
    "Source Han Sans CN", "SourceHanSansCN-Light", "webfontSourceHanSansSC" !important;
  .userbox {
    display: flex;
    padding: 20px;
    padding-bottom: 0;
    flex-wrap: nowrap;
    .avatar {
      width: 48px;
      height: 48px;
      background: #d0fcee;
      opacity: 0.15;
      border-radius: 12px;
    }
    .info {
      flex-shrink: 0;
      text-align: left;
      b,
      p {
        display: block;
        margin: 0;
        padding: 0;
        padding-left: 10px;
        line-height: 25px;
      }
      .name {
        display: block;
        height: 30px;
        line-height: 30px;
        font-size: 18px;
        font-weight: bold;
        color: #d0fcee;
      }
      .date {
        height: 25px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 400;
        color: #4c675e;
      }
    }
  }
  .content {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: #d0fcee;
    line-height: 26px;
    padding: 0 20px;
    margin: 5px 0;
    img {
      width: 100%;
    }
  }
}
</style>
